<template>
  <Drawer
    title="Adicionar classificação"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content>
      <div class="c-row ui-my-4">
        <div class="ui-ml-4 c-d-flex">
          <div class="c-d-flex">
            <c-pill
              class="ui-cursor-pointer"
              label="Início"
              :outline="!!currentCatalog.path"
              @click.native="handleClickCatalog()"
            />
          </div>
          <div
            class="c-d-flex"
            v-for="path in currentCatalog.path"
            :key="`path-${path.id}`"
          >
            <icon
              name="arrow-right"
              color="#999"
              width="24"
            />
            <c-pill
              class="ui-cursor-pointer"
              :label="path.label"
              :outline="!!path.id"
              @click.native="path.id ? handleClickCatalog(path.id) : null"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isLoading"
        class="c-row ui-justify-center ui-h-full ui-auto-mb"
        style="margin-top: 50%"
      >
        <icon
          name="loading"
          outline
          color="#3399FE"
          class="ui-spinner ui-animation-spin ui-animation-3s ui-animation-linear ui-animation-infinite"
          width="60"
          height="60"
        />
      </div>
      <div v-else>
        <c-table
          v-if="catalogs.length"
          :columns="columns"
          :data="catalogs"
          :items-per-page="itemsPerPage"
          :pagination="pagination"
          :is-clickable="true"
          row-variable-height
          class="ui-mt-8"
          @change-pagination-per-page="changePaginationPerPage"
          @change-pagination-current-page="changePaginationCurrentPage"
          @click="handleClickCatalog"
        >
          <template v-slot:column-name="{ item: catalog }">
            {{ catalog.alias ? `${catalog.alias} - ${catalog.name}`: catalog.name }}
          </template>
          <template v-slot:column-actions="{ item: catalog }">
            <clickable-icon
              name="add"
              hoverable
              class="ui-ml-4"
              @click="$emit('add-catalog', vertical, catalog.id)"
            />
          </template>
        </c-table>
        <div
          v-else
          class="c-row c-center ui-h-full"
          style="margin-top: 50%"
        >
          <span class="c-text-gray">
            Nenhuma classificação encontrada.
          </span>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { CPill, Icon } from '@estrategiahq/coruja-web-ui';
import CTable from '../c-table/CTable';
import ClickableIcon from '../clickable-icon';
import Drawer from '../drawer';

const itemsPerPage = [20, 50, 100];

export default {
  name: 'DrawerSearchCatalog',
  components: {
    CPill,
    Icon,
    CTable,
    ClickableIcon,
    Drawer,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    vertical: { type: String, required: true },
  },
  data () {
    return {
      isLoading: false,
      itemsPerPage,
      columns: [
        { attribute: 'name', label: 'Classificação' },
        { attribute: 'actions', label: '', maxWidth: '70px', justifyContent: 'end' },
      ],
      catalogs: [],
      pagination: {
        totalPages: 1,
        perPage: itemsPerPage[0],
        currentPage: 1,
      },
      currentCatalog: {
        id: '',
        path: [],
      },
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.search();
      } else {
        this.catalogs = [];
        this.currentCatalog.id = '';
        this.currentCatalog.path = [];
      }
    },
  },
  methods: {
    changePaginationPerPage (newPerPage) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = newPerPage;
      this.search();
    },
    changePaginationCurrentPage (newPage) {
      this.pagination.currentPage = newPage;
      this.search();
    },
    async handleClickCatalog (id) {
      this.currentCatalog.id = id;
      this.pagination.currentPage = 1;
      await this.search();
    },
    async search () {
      this.isLoading = true;
      try {
        if (this.currentCatalog.id) {
          await this.fetchById();
        } else {
          await this.fetch();
        }
      } catch (e) {
        this.isLoading = false;
        if (e.tag !== 'NOT_FOUND') {
          throw e;
        }
      }
      this.isLoading = false;
    },
    async fetch() {
      const { catalogs, total } = await this.$s.catalogs.getAll(this.vertical, { ...this.pagination });
      this.catalogs = catalogs;
      this.pagination.totalPages = Math.ceil(total / this.pagination.perPage);
      this.currentCatalog.path = [];
    },
    async fetchById() {
      const { catalog, total } = await this.$s.catalogs.getById(this.vertical, this.currentCatalog.id, { ...this.pagination });
      this.catalogs = catalog.items;
      this.pagination.totalPages = Math.ceil(total / this.pagination.perPage);

      const pathName = catalog.path_name.split('[??]');
      const path = catalog.path.split('.');
      path.shift();

      this.currentCatalog.path = pathName.map((value, index) => {
        return {
          id: path[index] ? path[index].replaceAll('_', '-') : null,
          label: value,
        };
      });
    },
  },
};
</script>
