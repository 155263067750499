<template>
  <div
    class="c-bg-white ui-px-6 ui-py-4"
    style="border-radius: 8px; box-shadow: 0 4px 16px rgba(34, 34, 34, 0.02);"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>
