<template>
  <div>
    <div v-if="catalog.name">
      {{ catalog.alias ? `${catalog.alias} - ${catalog.name}`: catalog.name }}
    </div>
    <div
      v-else
      class="loading-pill ui-mb-2"
    />
  </div>
</template>

<script>

export default {
  name: 'CatalogName',
  props: {
    id: { type: String, required: true },
    vertical: { type: String, required: true },
  },
  data () {
    return {
      catalog: {},
    };
  },
  watch: {
    async id () {
      this.fetch();
    },
  },
  async mounted () {
    await this.fetch();
  },
  methods: {
    async fetch () {
      const { catalog } = await this.$s.catalogs.getById(this.vertical, this.id, { perPage: 1, currentPage: 1 });
      this.catalog = catalog;
    },
  },
};

</script>

<style scoped>
.loading-pill {
  background: #ccc;
  opacity: 0.16;
  border-radius: 0.5rem;
  height: 1rem;
  width: 200px;
}
</style>
