<template>
  <form @submit.prevent>
    <slot />
  </form>
</template>

<script>

const isPInput = (component) => component.$options.name === 'PInput';
function getAllChildren (component, output = []) {
  // Se já é o PInput, adiciona na saída
  if (isPInput(component)) {
    output.push(component);
    return;
  }

  // Se o componente não tem mais filhos (e não é o PInput), ignora
  const children = component.$children;
  if (!children || children.length === 0) {
    return;
  }

  // Para todos os filhos do componente, busca os PInput
  children.forEach(c => getAllChildren(c, output));

  return output;
}

export default {
  name: 'PForm',
  methods: {
    valid (options = { dryRun: true, showErrors: false }) {
      return getAllChildren(this).every(c => c.validate(options));
    },
  },
};
</script>
