<!-- TODO: Refazer esse componente, usando a estrutura que já temos no bo-accounts, com uma API global $drawer.show() -->
<template>
  <div>
    <transition name="backdrop-animation">
      <div
        v-if="isOpen"
        class="drawer-backdrop"
        @click.stop="$emit('close')"
      />
    </transition>
    <transition name="drawer-slide">
      <div
        v-if="isOpen"
        class="drawer-container"
        :class="{ 'drawer-container-open': isOpen }"
      >
        <div class="c-row">
          <div class="c-col">
            <div class="c-text-subtitle">
              {{ title }}
              <button
                class="drawer-close"
                @click.stop="$emit('close')"
              >
                <icon
                  name="close"
                  color="#999"
                  width="36"
                  height="36"
                />
              </button>
            </div>
          </div>
        </div>
        <template>
          <slot name="content" />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'Drawer',
  components: { Icon },
  props: {
    title: { type: String, required: true },
    isOpen: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.drawer-slide-enter-active,
.drawer-slide-leave-active {
  transition: all 0.3s;
}
.drawer-slide-enter,
.drawer-slide-leave-to {
  transform: translateX(100%);
}
.backdrop-animation-enter-active,
.backdrop-animation-leave-active {
  transition: all 0.3s;
}
.backdrop-animation-enter,
.backdrop-animation-leave-to {
  background-color: rgba(34, 34, 34, 0);
}
.drawer-container {
  position: fixed;
  top: 0;
  right:-40%;
  height: 100%;
  width: 40%;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
}
.drawer-container-open {
  right: 0px;
  box-shadow: -15px 0px 17px -7px rgba(0,0,0,0.4);
}

.drawer-backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}

.drawer-close {
  outline: 0;
  vertical-align: middle;
  right: 10px;
  position: absolute;
}
</style>
