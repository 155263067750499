<template>
  <div class="c-row">
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Editar pagamento
          </span>
        </div>
      </div>

      <!-- Dados readonly do usuário -->
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card
            :items="[
              { label: 'ID', value: user.id },
              { label: 'Professor', value: user.full_name },
              { label: 'Email', value: user.email }
            ]"
          />
        </div>
      </div>

      <!-- Form: detalhes de pagamento do professor -->
      <p-form
        ref="form"
        class="ui-mb-4"
      >
        <ExpandableRow
          v-for="vertical of verticals"
          :key="`${vertical}_row`"
          :title="vertical"
          class="c-text-b2 c-text-gray-300"
          :data-cy="`paymentVerticalRow-${vertical}`"
        >
          <!-- Campo: porcentagem de pagamento por vertical -->
          <div class="c-row">
            <div class="c-col">
              <!-- TODO: Refatorar para usar o p-input -->
              <c-input
                class="ui-mt-2"
                type="number"
                :label="`Ganho da venda de material em ${vertical}`"
                v-model="payment[vertical].percentage"
                :data-cy="`paymentVerticalPercentage-${vertical}`"
              >
                <template slot="icon">
                  %
                </template>
              </c-input>
            </div>
            <div class="c-col">
              <c-button
                full
                outline
                class="c-col-full"
                style="max-width: 270px;"
                :data-cy="`addGainValue-${vertical}`"
                @click.stop="handleOpenDrawer(vertical)"
              >
                Adicionar ganho condicionado a uma classificação
              </c-button>
            </div>
          </div>
          <!-- Campo: porcentagem de pagamento por classificação e por vertical -->
          <div
            class="c-row ui-mt-6 ui-pt-4"
            style="border-top: 1px solid #E7E7E7"
            v-for="catalogId in Object.keys(payment[vertical].percentage_by_classification)"
            :key="`${vertical}-${catalogId}`"
          >
            <div class="c-col">
              <div class="c-row">
                <div class="c-col">
                  <CatalogName
                    :vertical="vertical"
                    :id="catalogId"
                  />
                </div>
              </div>
              <div class="c-row">
                <div class="c-col">
                  <!-- TODO: Refatorar para usar o p-input -->
                  <c-input
                    type="number"
                    label="Ganho na venda de material nessa classificação"
                    v-model="payment[vertical].percentage_by_classification[catalogId]"
                  >
                    <template slot="icon">
                      %
                    </template>
                  </c-input>
                </div>
              </div>
            </div>
            <div class="c-col">
              <clickable-icon
                name="trash"
                class="ui-mt-10"
                @click="handleRemoveCatalog(vertical, catalogId)"
              />
            </div>
          </div>
        </ExpandableRow>
      </p-form>

      <!-- Botões -->
      <div class="c-row c-horizontal-center">
        <div class="c-col-1/4 c-d-flex ui-justify-end">
          <c-button
            full
            outline
            class="c-col-full"
            data-cy="cancelButton"
            style="max-width: 164px;"
            @click="backToUsersList()"
          >
            Cancelar
          </c-button>
        </div>

        <div class="c-col-1/4">
          <c-button
            full
            type="submit"
            class="c-col-full"
            data-cy="saveButton"
            style="max-width: 164px;"
            :disabled="loadingError"
            @click="updatePayment"
          >
            Salvar
          </c-button>
        </div>
      </div>
    </div>
    <DrawerSearchCatalog
      :is-open="drawer.isOpen"
      :vertical="drawer.vertical"
      @close="handleCloseDrawer"
      @add-catalog="handleAddCatalog"
    />
  </div>
</template>

<script>
import { CButton, CInput } from '@estrategiahq/coruja-web-ui';
import CatalogName from '@/components/catalog-name';
import ClickableIcon from '@/components/clickable-icon';
import DrawerSearchCatalog from '@/components/drawer-search-catalog';
import ExpandableRow from '@/components/expandable-row';
import InfoCard from '@/components/info-card';
import PForm from '@/components/p-form';
import { historyBackTo } from '@/utils/historyBackTo';

const verticals = [
  'oab',
  'medicina',
  'vestibulares',
  'carreiras-juridicas',
  'concursos',
  'militares',
];

export default {
  components: {
    CButton,
    CInput,
    CatalogName,
    ClickableIcon,
    DrawerSearchCatalog,
    ExpandableRow,
    InfoCard,
    PForm,
  },
  data () {
    return {
      loadingError: false,
      user: {},
      payment: Object.fromEntries(verticals.map(vertical => ( [vertical, this.getDefaultPayment() ]))),
      verticals,
      drawer: {
        isOpen: false,
        vertical: '',
      },
    };
  },
  async mounted () {
    let userPayment = {};
    const id = this.$route.params.id;

    try {
      this.user = await this.$s.user.getById(id);
      const { payment } = await this.$s.user.getPaymentByUserId(id);
      userPayment = payment;
    } catch (e) {
      if (e.tag !== 'USER_PAYMENT.NOT_FOUND') {
        this.loadingError = true;
        throw e;
      }
    }

    for (const vertical of verticals) {
      if (userPayment[vertical]) {
        this.payment[vertical].percentage = userPayment[vertical].percentage.toString();
        Object.entries(userPayment[vertical].percentage_by_classification).forEach(([key, value]) => {
          this.payment[vertical].percentage_by_classification[key] = value.toString();
        });
        continue;
      }
      this.payment[vertical] = this.getDefaultPayment();
    }
  },
  methods: {
    getDefaultPayment() {
      return {
        percentage: '0',
        percentage_by_classification: {},
      };
    },
    handleOpenDrawer (vertical) {
      this.drawer.vertical = vertical;
      this.drawer.isOpen = true;
    },
    handleCloseDrawer () {
      this.drawer.vertical = '';
      this.drawer.isOpen = false;
    },
    handleAddCatalog (vertical, id) {
      this.handleCloseDrawer();
      this.payment[vertical].percentage_by_classification[id] = '0';
    },
    handleRemoveCatalog (vertical, id) {
      delete this.payment[vertical].percentage_by_classification[id];
      this.$forceUpdate();
    },
    async updatePayment () {
      let userPayment = {};
      for (const vertical of verticals) {
        userPayment[vertical] = {
          percentage: parseFloat(this.payment[vertical].percentage),
          percentage_by_classification: {},
        };
        Object.entries(this.payment[vertical].percentage_by_classification).forEach(([key, value]) => {
          userPayment[vertical].percentage_by_classification[key] = parseFloat(value);
        });
      }

      const updateUserPayment = {
        userId: this.user.id,
        payment: userPayment,
      };
      await this.$s.user.updatePayment(updateUserPayment);
      this.backToUsersList();
    },
    backToUsersList() {
      historyBackTo({
        key: 'users-list-search-url',
        router: this.$router,
        fallback: { name: 'users' },
      });
    },
  },
};
</script>
