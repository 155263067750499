<template>
  <card>
    <div
      v-if="!!items.length"
      class="c-row"
    >
      <div
        v-for="item of items"
        class="c-col"
        :key="item.label"
      >
        <!-- Label -->
        <div class="c-row">
          <div class="c-col">
            <span class="c-text-label c-text-primary">
              {{ item.label }}
            </span>
          </div>
        </div>

        <!-- Valor -->
        <div class="c-row">
          <div class="c-col">
            <span
              class="c-text-b3 c-row c-col ui-mt-1"
              v-if="item.tagStatus"
              :data-cy="item.dataCy"
            >
              {{ item.value || '' }}
              <c-tag
                class="ui-mt-1 ui-ml-1"
                :background-color="tagBgColor(item.tagStatus)"
                :text-color="tagTextColor(item.tagStatus)"
              >
                {{ item.tagValue }}
              </c-tag>
            </span>
            <span
              v-else
              class="c-text-b3"
              :class="item.value ? 'c-text-gray-400' : 'c-text-red-400'"
              :data-cy="item.dataCy"
            >
              {{ item.value | maskValue(item.mask) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="c-row c-col ui-justify-center c-text-gray-300"
    >
      <span class="c-text-b2">
        Não há itens a serem exibidos
      </span>
    </div>
  </card>
</template>

<script>
import Card from '../card';
import { CTag } from '@estrategiahq/coruja-web-ui';
import { maskValue } from '@/utils/strings';

const TAG_STATUS = {
  success: {
    bgColor: 'var(--c-color-green)',
    textColor: 'var(--c-color-white)',
  },
  danger: {
    bgColor: 'var(--c-color-red)',
    textColor: 'var(--c-color-white)',
  },
  inactive: {
    bgColor: 'var(--c-color-gray)',
    textColor: 'var(--c-color-white)',
  },
  orange: {
    bgColor: 'var(--c-color-orange)',
    textColor: 'var(--c-color-white)',
  },
};

export default {
  name: 'InfoCard',
  components: { Card, CTag },
  props: {
    // se o item possuir tagStatus, precisa ser um status válido (presente no TAG_STATUS)
    items: { type: Array, required: true, validator: (items) => !items.some(i => i.tagStatus && !TAG_STATUS[i.tagStatus]) },
  },
  filters: {
    maskValue,
  },
  methods: {
    tagBgColor: (tagStatus) => TAG_STATUS[tagStatus].bgColor,
    tagTextColor: (tagStatus) => TAG_STATUS[tagStatus].textColor,
  },
};
</script>
